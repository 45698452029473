import AppRouter from 'containers/App'
import React from 'react'
import ReactDOM from 'react-dom'
import './assets/style.scss'
import { HashRouter } from 'react-router-dom'
ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <AppRouter />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
