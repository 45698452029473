import { Fragment, Suspense, lazy } from 'react'
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom'
const ProfileCV = lazy(() => import('./ProfileCV'))
function AppRouter() {
  return (
    <BrowserRouter>
      <Suspense fallback>
        <Switch>
          <Route path={'/#/profile/dev'} component={ProfileCV} />
          <Redirect
            to={{
              pathname: '/#/profile/dev',
            }}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default AppRouter
